
import { Vue, Component, Prop } from 'vue-property-decorator';
import Tooltip from '@/components/Tooltip/Tooltip.vue';

interface NewIconInfo {
  label: string,
  icon: string,
}

type OldIconInfo = string;

@Component({
  name: 'Icon',
  components: {
    Tooltip,
  },
})
export default class Icon extends Vue {
  @Prop({ default: 28 })
  private readonly size!: number

  @Prop()
  private readonly icon!: NewIconInfo|OldIconInfo

  getIcon(): string {
    if (typeof this.icon === 'string') {
      return this.icon;
    }

    return this.icon.icon;
  }

  protected getDescription(): string|undefined {
    return (this.icon as { label: string|undefined }).label;
  }
}
